import React, { useState, useEffect } from 'react';
import PhoneNumber from '../components/PhoneNumber';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ArrowPathIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { useUserData } from '../context/UserData';

const Users = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // null for new user, user object for editing
  const [formData, setFormData] = useState({ username: '', phone: '', email: '' });
  const navigate = useNavigate();
  const userData = useUserData();

  // State for confirmation modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users_crud.php?getList=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        });
        if (response.data.location === 'field') {
          navigate('/t'); // Redirect to login if the cookie is invalid or expired
          // toast.error('Tech Section', {
          //   position: 'top-center',
          // });
        } else {
          setUsers(response.data.users || []); // Ensure users is an array
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', {
            position: 'top-center',
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      }
    };

    fetchUsers();
  }, [apiUrl, navigate]);

  useEffect(() => {
    if (currentUser) {
      setFormData({
        username: currentUser.username || '',
        phone: currentUser.phone || '',
        email: currentUser.email || '',
      });
    } else {
      setFormData({ username: '', phone: '', email: '' });
    }
  }, [currentUser]);  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const sanitizedValue = value.replace(/\D/g, '');
      if (sanitizedValue.length > 10) {
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentUser) {
        // Update user
        const bodyData = {
          status: 'updateUser',
          data: {
            id: currentUser.id,
            ...formData,
          },
        };
        const response = await axios.post(`${apiUrl}/users_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data.success) {
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === currentUser.id ? { ...user, ...formData } : user
            )
          );
          toast.success('User updated successfully', { position: 'top-center' });
        } else {
          toast.error('Failed to update user');
        }
      } else {
        // Create new user
        const bodyData = {
          status: 'addUser', // Corrected from 'updateUser' to 'addUser'
          data: formData,
        };
        const response = await axios.post(`${apiUrl}/users_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data.success) {
          setUsers((prevUsers) => [...prevUsers, response.data.user]);
          toast.success('User created successfully');
        } else {
          toast.error('Failed to create user');
        }
      }
      setShowModal(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      } else {
        console.error('Error submitting form:', error);
        toast.error('An unexpected error occurred.');
      }
    }
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    if (!userToDelete) return;
    const bodyAddData = {
      status: 'removeUser',
      user_id: userToDelete.id,
    };
    try {
      const response = await axios.post(`${apiUrl}/users_crud.php`, bodyAddData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));
        toast.success('User removed successfully!', { position: 'top-center' });
        setShowConfirmModal(false);
        setUserToDelete(null);
      } else {
        toast.info('Failed to remove user', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      } else {
        console.error('Error deleting user:', error);
        toast.error('An unexpected error occurred.');
      }
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setUserToDelete(null);
  };

  return (
    <div>
      {/* Button to add new users */}
      {userData?.users?.department === 'Admin' ? (
        <>
          <button
            className="mb-4 px-4 py-2 bg-indigo-600 text-white rounded-md float-right sm:flex sm:items-right"
            onClick={() => {
              setCurrentUser(null); // Set to null to indicate a new user
              setShowModal(true); // Show the modal
            }}
          >
            Add New User
          </button>
          <br />
          <br />
          <br />
        </>
      ) : null}

      {/* User list */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-6">
        {Array.isArray(users) &&
          users.map((user) => (
            <div
              key={user.id}
              className="relative flex items-center space-x-3 rounded-lg border
                         border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 
                         focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
              {userData?.users?.department === 'Admin' ? (
                <>
                  {/* Icons for admin users */}
                  <TrashIcon
                    className="-mr-1 h-5 w-5 text-red-400 absolute top-4 right-4 hover:cursor-pointer"
                    onClick={() => handleDeleteClick(user)}
                    aria-hidden="true"
                  />
                  <ArrowPathIcon
                    onClick={() => navigate(`/employee_edit/${user.id}`)}
                    className="-mr-1 h-5 w-5 text-green-600 absolute top-17 right-3 hover:cursor-pointer"
                    aria-hidden="true"
                  />
                  <PencilSquareIcon
                    className="-mr-1 h-5 w-5 text-blue-600 absolute top-20 right-3 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      setCurrentUser(user); // Set the user to be edited
                      setShowModal(true); // Show the modal
                    }}
                  />
                </>
              ) : null}
              <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-gray-900">ID: {user.id}</p>
                <p className="text-sm font-medium text-gray-900">{user.username}</p>
                <p className="truncate text-sm text-gray-500">
                  <PhoneNumber number={user.phone} />
                </p>
                <p className="truncate text-sm text-gray-500">{user.email}</p>
              </div>
            </div>
          ))}
      </div>

      {/* Modal for adding/editing users */}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen text-center">
            {/* Background overlay */}
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Modal panel */}
            <div
              className="inline-block bg-white rounded-lg text-left 
              overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <form onSubmit={handleSubmit}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {currentUser ? 'Edit User' : 'Add New User'}
                    </h3>
                    <div className="mt-2">
                      {/* Username field */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                          type="text"
                          name="username"
                          value={formData.username}
                          onChange={handleInputChange}
                          className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      {/* Phone field */}
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          maxLength="10"
                          className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      {/* Email field */}
                      <div className="mt-2">
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>
                      {/* Additional fields can be added here */}
                    </div>
                  </div>
                </div>
                {/* Modal actions */}
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent 
                    shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {currentUser ? 'Update' : 'Create'}
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 
                    shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {showConfirmModal && userToDelete && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen text-center">
            {/* Background overlay */}
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Modal panel */}
            <div
              className="inline-block bg-white rounded-lg text-left 
              overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Confirm Deletion
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete user{' '}
                      <strong>{userToDelete.username}</strong>? This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
              {/* Modal actions */}
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  onClick={confirmDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent 
                  shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  onClick={cancelDelete}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 
                  shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
