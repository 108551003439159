import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CurrencyFormat from '../components/CurrencyFormat';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import axios from 'axios'; // Make sure Axios is imported
import SearchBarCP from '../components/SearchBarCP';
import { CloseButton, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindow from '../components/ModalWindow';
import ModalNotesSection from '../components/ModalNotesSection';
import ProgressCustomBar from '../components/ProgressCustomBar';
import Truncate from '../components/Truncate';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';


const Engineering = () => {
  const navigate = useNavigate();
  const [engineering, setEngineering] = useState({
                                                   id:'',
                                                   job_number:'',
                                                   job_site:'',
                                                   engineering_notes:'',
                                                   paperwork:'',
                                                   approved_paperwork:'',
                                                   user_id:'',
                                                   employee_name:'',
                                                   date:'',
                                                   estimate_time:'',
                                                   job_status:'',
                                                   paperwork_type:'',
                                                   stage:''
                                                  });
  const [filteredEngineering, setFilteredEngineering] = useState([]);
  const [showAppoved, setShowApproved] = useState(true);
  const [bodyDataNotes, setBodyDataNotes] = useState({
                                                        status: 'submit_notes',
                                                        user_id: '',
                                                        data: {}
                                                      });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null); // Stores the ID of the job for which notes are shown
  const [engID, setEngID] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);

  const convertNotes = (notes) => {
    if (notes.length > 120 ){
       return ( 
                  notes.substring(0, 120) 
                  
              );
    } else {
      return notes;
    }
  }

  const userData = useUserData();

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/engineering_crud.php`;

  useEffect(() => {
    fetchEngineering();
  }, []);

  const toggleShowApproved = () => {
    setShowApproved(!showAppoved);
  }

  const fetchEngineering = async () => {
        try {
        const response = await axios.get(`${apiUrl}/engineering_crud.php`, {
          params: {
            getList: 'list'
          }
        });

        const data = response.data;
        // console.log(data); //delete
        if (data.error) {
          toast.error(data.error, {
            position: 'top-center'
          });
        }
        setEngineering(data);
        setFilteredEngineering(data);
        // setEmployee(data.employees);
      } catch (error) {
        console.log('Error fetching data', error);
        // navigate('/customers')
      } finally {
        setLoading(false);
      }
  };

  const handleSearchChange = (event) => {
     const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
        setFilteredEngineering(engineering);
    } else {
        const filtered = engineering.filter(engineering =>
            (engineering.description && engineering.description.toLowerCase().includes(value.toLowerCase())) ||
            (engineering.job_site && engineering.job_site.toLowerCase().includes(value.toLowerCase())) ||
            (engineering.job_number && engineering.job_number.toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredEngineering(filtered);
    }
  };

  const handleSearchClick = async () => {
    //console.log('Search button clicked, term:', searchTerm);
    setLoading(true);

    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(`${apiUrl}/engineering_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      //console.log('Search response:', response.data);//delete 
      if (response.data && Array.isArray(response.data)) {
        setEngineering(response.data);
        setFilteredEngineering(response.data);
      } else {
        handleSearchChange()
        setEngineering([]);
        setFilteredEngineering([]);
        toast.error('not found '+searchTerm, {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error searching active jobs', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotes = (var_test) =>{
    // const value = event.target.value;
    setOpenModalId(var_test.idItem)
  }

  const handleChange = (event, id, user) => {
    bodyDataNotes.user_id = user.user_id;
      const { name, value } = event.target;
      setFilteredEngineering(prev => prev.map(engineer => {
          if (parseInt(engineer.id) === id) {
              // Create an additional variable or perform additional logic here
              const updatedEngineer = { ...engineer, [name]: value };
              bodyDataNotes.data = updatedEngineer;
              // // You can also update the updatedEngineer object if needed
              updatedEngineer.employee_name = user.first_name+" "+user.last_name;
              return updatedEngineer;
          } else {
              return engineer;
          }
      }));
  };

  const handleComplete = async (e) => {
    //console.log(e);
    
        try {
            const response = await axios.post(api, {status: 'submit_complete', id:e.idItem}, {
                headers: { 'Content-Type': 'application/json' }
            });

            toast.info('Saved!', {
                position: 'top-center'
            });
            //console.log(response.data);

        } catch (error) {
            toast.error("Oops minor issue with the data.", {
            position: "top-center"
            });
            
            if (error.response && error.response.status === 401) {
            toast.error("Session expired. Please login again.", {
            position: "top-center"
            });
            navigate('/login');
            } else if (error.response && error.response.status === 403) {
            // setAccess(true);
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
            });
            }
        }
  }

  const handleApproval = async (e) => {
    //console.log(e);
        try {
            const response = await axios.post(api, {status: 'submit_approval', id:e.idItem}, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.data[0] === 'success') {
                toast.info('Saved!', {
                  position: 'top-center'
                });
                setFilteredEngineering(engineering => engineering.map(project => {
              if (project.id === e.idItem) {
                return { ...project, approved_paperwork: 1 };
              }
                  return project;
              }));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
            toast.error("Session expired. Please login again.", {
            position: "top-center"
            });
            navigate('/login');
            } else if (error.response && error.response.status === 403) {
            // setAccess(true);
            toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
            });
            }
        }
  }

  const voidJobSubmit = async(id, currentVoidState) => {
    setLoading(true);
    
    const newVoidStatus = currentVoidState === 'void' ? '' : 'void';
    const bodyData = {
      status: 'void',
      data: { voidID: id, void:newVoidStatus }
    };

    try {
      const response = await axios.post(`${apiUrl}/engineering_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data === 'Success') {
          setFilteredEngineering(engineering => engineering.map(project => {
        if (project.id === id) {
          return { ...project, job_status: newVoidStatus };
        }
            return project;
        }));

        toast.success('Saved!', {
          position: 'top-center'
        })
      } else {
        toast.error('error updating', {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error saving project', error);
    } finally {
      setLoading(false);
    }
  }

  const getLinksForJob = (engineering) => {
    let links = [
        { name: 'Edit Job', click: handleNotes},
        { name: 'Complete', click: handleComplete },
        { name: 'Approve PW', click: handleApproval },
        { name: engineering.job_status === 'void' ? 'Remove void' : 'Void project', click: (e, tvoid, index) => voidJobSubmit(engineering.id, engineering.job_status, index) },
    ];
    return links;
  };

  const handleNotesView = (e) => {
    setNotesIdOpen(e);
  }

  return (
    <>
       
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">Engineering Projects</h1>
         <LegendCP color="fill-green-300" label="Complete" />
         <br/>
         <br/>

        <div className="flex space-x-3 items-center mb-10">
        <button
          onClick={toggleShowApproved}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
          {showAppoved ? 'Show Complete' : 'Hide Complete'}
        </button>

          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner children={'Loading...'}/>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID/Edit
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                  Employee Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                  Proposal | Job Site | Eng. Cont.
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-[50px]">
                  Approved PaperWork
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-[50px]">
                  PaperWork Type
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-2">
                  Work in progress
                </th> 
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Notes
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>

              {filteredEngineering.filter(engineer => showAppoved && engineer.job_status=='complete' ? engineer.approved_paperwork !== 1 : engineer).map((engineer, index) => (
              <tr key={engineer.id} className={`${engineer.job_status == 'void' ? 'line-through text-red-600' : ''} 
                                                ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} 
                                                ${engineer.job_status === 'complete' ? 'bg-green-300' :''} 
                                                divide-x`}>
                {/* edit */}
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                    <SubMenu label={engineer.id} links={getLinksForJob(engineer)} idItem={engineer.id}/>

                    {openModalId === engineer.id && (
                        <ModalWindow job={
                              <ModalNotesSection
                                onChange={(e) => handleChange(e, engineer.id, userData?.users)}
                                api={api}
                                inside={'engineering_notes'}
                                val={engineer}
                                bodyData={bodyDataNotes}
                                redirectPath={'/engineering/list'}
                                closeNotes={() => setOpenModalId(null)}
                            />
                        } closeNotes={() => setOpenModalId(null)} />
                    )}
                    <br />
                    <dl className="font-normal lg:hidden">
                    <dd className="mt-1 truncate text-gray-700">{engineer.employee_name}</dd>
                    <dd className="mt-1 truncate text-gray-500 sm:hidden">{engineer.job_address}</dd>
                    <dd className="mt-1 truncate text-gray-500 sm:hidden">{engineer.date}</dd>
                  </dl>
                </td>

                {/* employee name */}
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{engineer.employee_name}</td>

                {/* proposal */}
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {engineer.job_number}<br/>
                  <span className='text-gray-400'>
                    {engineer.job_site}
                  </span>
                  <br/>
                  <span className='text-green-600 font-semibold'>
                    {engineer.contract_engineer && (engineer.contract_engineer)}
                  </span>
                </td>

                {/* approved_paperwork */}
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {engineer.approved_paperwork === 1? 'Yes' : 'No'}
                </td>

                {/* paperwork type */}
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {engineer.paperwork_type}
                </td>

                {/* wip */}
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {engineer.paperwork}
                </td>

                {/* notes */}
                <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell max-w-[350px]">
                  {engineer.engineering_notes && 
                    <Truncate text={engineer.engineering_notes} handleNotesView={
                      ()=>handleNotesView(engineer.id)
                    }/>
                  }
                  { notesIdOpen == engineer.id && (
                    <ModalWindow job={engineer.engineering_notes} closeNotes={() => setNotesIdOpen(null)} activeButton={true}/>
                  )}
                </td>

                {/* date */}
                <td className="hidden pl-2 py-4 text-sm text-gray-500 sm:table-cell">{<DateFormat date={engineer.date}/>}</td>
              </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default Engineering;