import React, { useState, useEffect } from 'react';
import PhoneNumber from '../components/PhoneNumber';
import { NavLink, useNavigate } from 'react-router-dom';
import AvatarLocation from '../components/AvatarLocation';
import {
  DocumentMagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
  TruckIcon,
} from '@heroicons/react/24/outline';
import BadgeFieldOffice from './BadgeFieldOffice';
import { useUserData } from '../context/UserData';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeList = ({ searchTerm }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const userData = useUserData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function ylasttwo(dateString) {
    const year = new Date(dateString).getFullYear().toString();
    const lastTwoDigits = year.slice(-2);
    return lastTwoDigits;
  }

  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [file, setFile] = useState(null);

  // State for confirmation modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  // State for avatar update modal
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [employeeToUpdateAvatar, setEmployeeToUpdateAvatar] = useState(null);


  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const res = await fetch(`${apiUrl}/employee_crud.php?getList=1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        setEmployees(data);
        setFilteredEmployees(data);
      } catch (error) {
        console.log('Error fetching data', error);
      }
    };
    fetchEmployees();
  }, [apiUrl]);

  useEffect(() => {
    const filtered = employees.filter(
      (employee) =>
        employee.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.department.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEmployees(filtered);
  }, [searchTerm, employees]);  

  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    if (!employeeToDelete) return;

    const bodyData = {
      status: 'deleteEmployee',
      employee_id: employeeToDelete.id,
    };
    try {
      const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        setEmployees((prevItems) =>
          prevItems.filter((item) => item.id !== employeeToDelete.id)
        );
        toast.success('Employee deleted!', {
          position: 'top-center',
        });
        setShowConfirmModal(false);
        setEmployeeToDelete(null);
      } else {
        toast.info('Failed to delete the Employee.', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error(
          "Oops!! You don't have access to this area. Speak to the CFO.",
          {
            position: 'top-center',
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setEmployeeToDelete(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only JPEG, PNG, or GIF images are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  const handleUploadFile = async (e, employee_id) => {
    e.preventDefault();
    if (!file) {
      toast.error('Please select a file before uploading.', { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'submit_avatar');
    formData.append('id', employee_id);
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/avatar_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.success) {
        toast.info('Avatar uploaded successfully!', { position: 'top-center' });

        // Update the employees state to reflect the new avatar
        setEmployees((prevEmployees) =>
          prevEmployees.map((emp) =>
            emp.id === employee_id ? { ...emp, avatar: response.data.avatar } : emp
          )
        );

        setFile(null);
        setShowAvatarModal(false);
        setEmployeeToUpdateAvatar(null);
      } else {
        toast.error(response.data.error || 'Error uploading avatar', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred during avatar upload.', {
        position: 'top-center',
      });
      console.error('Error uploading avatar', error);
    }
  };

  const handleAvatarClick = (employee) => {
    setEmployeeToUpdateAvatar(employee);
    setShowAvatarModal(true);
  };

  return (
    <div className='mb-8'>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-4xl"
      >
        {filteredEmployees.map((employee) => (
          <li
            key={employee.id}
            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
          >
            <div className="flex flex-1 flex-col p-8">
              {userData?.users.department === 'Admin' && (
                <TrashIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-red-500 hover:cursor-pointer self-end"
                  onClick={() => handleDeleteClick(employee)}
                />
              )}
              <span className="absolute text-gray-400 text-sm relative top-0">
                ID: {employee.id}-{ylasttwo(employee.date_hire)}
              </span>

              {/* Avatar with hover effect */}
              {userData?.users.department === 'Admin' ? (
              <>
                <div className="relative mx-auto h-32 w-32 flex-shrink-0 rounded-full cursor-pointer"
                  onClick={() => handleAvatarClick(employee)}>
                    <AvatarLocation className="h-32 w-32 rounded-full" avatar={employee.avatar}/>
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-full">
                    <span className="text-white text-sm">Update Avatar</span>
                  </div>
                </div>                
              </>
              ):
              (
                 <AvatarLocation className="h-32 w-32 rounded-full" avatar={employee.avatar}/>
              )
            }

              <h3 className="mt-6 text-sm font-medium text-gray-900">
                {employee.first_name} {employee.last_name}
              </h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-sm text-gray-500">
                  {employee.title} <br />{' '}
                  <span className="text-blue-400">{employee.department}</span>
                </dd>
                <dd className="text-sm text-gray-500">
                  <PhoneNumber number={employee.phone} />
                </dd>
                <dd className="text-sm text-gray-500">{employee.email}</dd>
                <dt className="sr-only">Role</dt>
                <dd className="mt-3">
                  <BadgeFieldOffice location={employee.o_f_type} />
                </dd>
                <dd className="mt-3">
                  {employee.fleet.vnumber ? (
                    <div className="flex items-center justify-center">
                      <TruckIcon className="h-5 w-5 text-gray-400" />
                      <span>{': ' + employee.fleet.vnumber}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </dd>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                {userData?.users.department === 'Admin' ? (
                  <div className="flex w-0 flex-1">
                    <NavLink
                      to={`/employee_edit/${employee.user_id}`}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center
                                              gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-800"
                    >
                      <PencilIcon className="h-5 w-5 text-green-700" aria-hidden="true" />
                      Edit
                    </NavLink>
                  </div>
                ) : null}
                <div className="-ml-px flex w-0 flex-1">
                  <NavLink
                    to={`/profile/${employee.id}`}
                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <DocumentMagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Profile
                  </NavLink>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* Confirmation Modal */}
      {showConfirmModal && employeeToDelete && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen text-center">
            {/* Background overlay */}
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Modal panel */}
            <div
              className="inline-block bg-white rounded-lg text-left 
              overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Confirm Deletion
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete employee{' '}
                      <strong>
                        {employeeToDelete.first_name} {employeeToDelete.last_name}
                      </strong>
                      ? This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
              {/* Modal actions */}
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  onClick={confirmDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent 
                  shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  onClick={cancelDelete}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 
                  shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Avatar Update Modal */}
      {showAvatarModal && employeeToUpdateAvatar && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen text-center">
            {/* Background overlay */}
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Modal panel */}
            <div
              className="inline-block bg-white rounded-lg text-left 
              overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <form onSubmit={(e) => handleUploadFile(e, employeeToUpdateAvatar.id)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Update Avatar for {employeeToUpdateAvatar.first_name}{' '}
                      {employeeToUpdateAvatar.last_name}
                    </h3>
                    <div className="mt-2">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="mt-1 block w-full text-sm text-gray-900
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100"
                      />
                    </div>
                  </div>
                </div>
                {/* Modal actions */}
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent 
                    shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowAvatarModal(false)}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 
                    shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeList;
