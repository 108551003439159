import './App.css';
import './custom.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import ProposalListPage from './pages/ProposalListPage';
import ProposalNew from './pages/ProposalNew';
import WipNew from './pages/WipNew';
import WipListPage from './pages/WipListPage';
import UsersListPage from './pages/UsersListPage';
import EmployeeListPage from './pages/EmployeeListPage';
import EmployeePage from './pages/EmployeePage';
import EmployeeEditPage from './pages/EmployeeEditPage';
import Login from './pages/Login';
import NotFoundPage from './pages/NotFoundPage';
import JobGen from './pages/JobGen';
import CustomerList from './pages/CustomerList';
import CustomerPage from './pages/CustomerPage';
import CustomerBillTo from './pages/CustomerBillTo';
import CustomerBillToEdit from './pages/CustomerBillToEdit';
import AccountingVendorPage from './pages/AccountingVendorPage';
import WipDetailPage from './pages/WipDetailPage';
import TechProfilePage from './pages/TechProfilePage';
import ServiceProposalPage from './pages/ServiceProposalPage';
import ServiceCallNew from './pages/ServiceCallNew';

// Tech imports
import TechLayout from './layouts/TechLayout';
import TechMain from './mobile/TechMain';
import Engineering from './pages/Engineering';
import InvoiceUpdate from './pages/InvoiceUpdate';
import InvHistory from './pages/inv_history';
import SettingsPage from './pages/SettingsPage';
import OfficeCalendar from './pages/OfficeCalendar';
import VacationYtD from './pages/VacationYtD';
import AttendancePage from './pages/AttendancePage';
import TechTimesheetPage from './pages/TechTimesheetPage';
import TechWipPage from './mobile/TechWipPage';
import TechWkTimesheet from './mobile/TechWkTimesheet';
import TechWkTimesheetLog from './pages/TechWkTimesheetLog';
import ProfilePage from './pages/ProfilePage';
import CreditHold from './pages/CreditHold';
import VacationRequestPage from './pages/VacationRequestPage';
import TechCreditHoldPage from './pages/TechCreditHoldPage';
import TechVacationRequestPage from './pages/TechVacationRequestPage';
import TechCheckinOut from './mobile/TechCheckinOut';
import OnCallList from './pages/OnCallListPage';
import DeliveryTrackingPage from './pages/DeliveryTrackingPage';
import PayrollPage from './pages/PayrollPage';
import ServiceCallList from './pages/ServiceCallList';
import EstimatingListPage from './pages/EstimatingListPage';
import EstimatingProcessPage from './pages/EstimatingProcessPage';
import InstallationDetailPage from './pages/InstallationDetailPage';
import EstimateEquipmentAdd from './pages/EstimateEquipmentAdd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EstimateWire from './pages/EstimateWire';
import AlertsView from './pages/AlertsView';
import OfficeTimesheet from './pages/OfficeTimesheet';
import OfficeTimesheetList from './pages/OfficeTimesheetList';
import EstimateContractor from './pages/EstimateContractor';
import EstimateNotes from './pages/EstimateNotes';
import EstimateMisc from './pages/EstimateMisc';
import EstimateDetail from './pages/EstimateDetail';
import EstimateProposal from './pages/EstimateProposal';
import TechServiceList from './mobile/TechServiceList';
import TechServiceDetail from './mobile/TechServiceDetail';
import TechSignature from './mobile/TechSignature';
import ServiceTicket from './pages/ServiceTicket';
import ServiceCalView from './pages/ServiceCalView';
import CheckInOutPage from './pages/CheckInOutPage';
import ContractsPage from './pages/ContractPage';
import MaintenanceContractNew from './pages/MaintenanceContractNew';
import ServicePartList from './pages/ServicePartList';


const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'proposal_list', element: <ProposalListPage /> },
      { path: 'proposal/:key/:id', element: <ProposalNew /> },
      { path: 'wip/:key/:id', element: <WipNew /> },
      { path: 'wip_list/:key', element: <WipListPage /> },
      { path: 'wip_detail/:key', element: <WipDetailPage /> },
      { path: 'inv_history/:key', element: <InvHistory /> },
      { path: 'invoice_update', element: <InvoiceUpdate /> },
      { path: 'users_list', element: <UsersListPage /> },
      { path: 'employee_list', element: <EmployeeListPage /> },
      { path: 'employee_details/:id', element: <EmployeePage /> },
      { path: 'employee_edit/:id', element: <EmployeeEditPage /> },
      { path: 'job_gen', element: <JobGen /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'customer/:key/:id', element: <CustomerPage /> },
      { path: 'customer_billto/:key/:id', element: <CustomerBillTo /> },
      { path: 'customer_billto_edit/:customerID/:billtoID/:key', element: <CustomerBillToEdit /> },
      { path: 'engineering/:key', element: <Engineering /> },
      { path: 'accounting', element: <AccountingVendorPage /> },
      { path: 'settings/:key', element: <SettingsPage /> },
      { path: 'office_calendar', element: <OfficeCalendar /> },
      { path: 'vacation_ytd', element: <VacationYtD /> },
      { path: 'attendance/:key', element: <AttendancePage /> },
      { path: 'tech_timesheet', element: <TechTimesheetPage /> },
      { path: 'profile/:key', element: <ProfilePage /> },
      { path: 'credit_hold', element: <CreditHold /> },
      { path: 'vacation_req', element: <VacationRequestPage /> },
      { path: 'on_call', element: <OnCallList /> },
      { path: 'delivery_tracking', element: <DeliveryTrackingPage /> },
      { path: 'service_proposal', element: <ServiceProposalPage /> },
      { path: 'payroll_page', element: <PayrollPage /> },
      { path: 'service_dashboard', element: <ServiceCallList /> },
      { path: 'service_new/:id', element: <ServiceCallNew /> },
      { path: 'service_pl', element: <ServicePartList /> },
      { path: 'service_cal', element: <ServiceCalView /> },
      { path: 'estimate', element: <EstimatingListPage /> },
      { path: 'estimate_pro/:id', element: <EstimatingProcessPage /> },
      { path: 'estimate_contr/:id', element: <EstimateContractor /> },
      { path: 'estimate_notes/:id', element: <EstimateNotes /> },
      { path: 'estimate_misc/:id', element: <EstimateMisc /> },
      { path: 'estimate_detail/:id', element: <EstimateDetail /> },
      { path: 'estimate_proposal/:id', element: <EstimateProposal /> },
      { path: 'installation_details/:id', element: <InstallationDetailPage /> },
      { path: 'estimate_equipment/:id', element: <EstimateEquipmentAdd /> },
      { path: 'estimate_wire/:id', element: <EstimateWire /> },
      { path: 'alerts_view', element: <AlertsView /> },
      { path: 'office_timesheet', element: <OfficeTimesheet /> },
      { path: 'office_timesheet_list', element: <OfficeTimesheetList /> },
      { path: 'vcheckinout', element: <CheckInOutPage /> },
      { path: 'maintenance', element: <ContractsPage /> },
      { path: 'maintenance_contract/:key/:id', element: <MaintenanceContractNew /> }
    ]
  },
  {
    path: '*', element: <NotFoundPage /> 

  },
  {
     path: 'login', element: <Login /> 
  },
  {
     path: 'service_ticket/:id', element: <ServiceTicket />
  },
  {
    path: '/t',
    element: <TechLayout />,
    children: [
      { index: true, element: <TechMain /> },
      { path: 'twip', element: <TechWipPage /> },
      { path: 'tservlist', element: <TechServiceList /> },
      { path: 'tservdetail/:id', element: <TechServiceDetail /> },
      { path: 'tservsignature/:id', element: <TechSignature /> },
      { path: 'twkt', element: <TechWkTimesheet /> },
      { path: 'twkl/:id', element: <TechWkTimesheetLog /> },
      { path: 'tp/:key', element: <TechProfilePage /> },
      { path: 'tcredit_hold', element: <TechCreditHoldPage /> },
      { path: 'tvacationreq', element: <TechVacationRequestPage /> },
      { path: 'tchkinout', element: <TechCheckinOut /> },
    ]
  }
]);

function App() {
  return (
    <>
    <ToastContainer />
    <RouterProvider router={router}>
    </RouterProvider>
    </>
  );
}

export default App;
