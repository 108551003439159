import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SelectAddress from '../components/SelectAddress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify'; 

const ServiceCallFormModal = ({
  isOpen,
  onClose,
  isEditing,
  formData,
  onInputChange,
  onSubmit,
  selectedCompany,
  handleCompanySelect
}) => {

const apiUrl = process.env.REACT_APP_API_URL;
const [employees, setEmployees] = useState([]);
const navigate = useNavigate();

useEffect(() => {
    const employees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        });
        const serviceCalls = Array.isArray(response.data) ? response.data : [];
        if (serviceCalls.length === 0) {
          toast.error('No service calls found!', { position: 'top-center' });
        }
        setEmployees(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error('Session expired. Please login again.', { position: 'top-center' });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: 'top-center',
          });
        }
      } 
    };

    employees();
}, [apiUrl, navigate]);


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className="fixed inset-0 overflow-y-auto bg-white bg-opacity-70">
         
            <div className="flex min-h-screen items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-xl w-full bg-white rounded-lg p-6 shadow-lg">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  {isEditing ? 'Edit Service Call' : 'Add New Service Call'}
                </Dialog.Title>
                <form onSubmit={onSubmit} className="mt-4 space-y-4">

                  {/* TROUBLE */}
                  <div>
                    <label htmlFor="TROUBLE" className="block text-sm font-medium text-gray-700">
                      Trouble <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="TROUBLE"
                      id="TROUBLE"
                      value={formData.TROUBLE}
                      onChange={onInputChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                        focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  {/* Select Job Site */}
                    {formData.ADDRESS ? (
                      <div className="col-span-full">
                      <label htmlFor="ADDRESS" className="block text-sm font-medium text-gray-900">
                        Job Site <span className="text-red-500">*</span> | Note: by clearing the job site field (activates the search engine)
                      </label>
                      <input
                        type="text"
                        id="ADDRESS"
                        name="ADDRESS"
                        value={formData.ADDRESS || ''}
                        onChange={onInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </div>
                    ) : (
                      <div className="sm:col-span-3">
                        <SelectAddress
                          label="Select Job Site"
                          // items={jobSite}
                          selectedItem={selectedCompany}
                          setSelectedItem={handleCompanySelect}
                          apiUrl={`${apiUrl}/service_crud.php`}
                        />
                      </div>
                    )}

                  {/* CUSTOMER */}
                  <div>
                    <label htmlFor="CUSTOMER" className="block text-sm font-medium text-gray-700">
                      Customer <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="CUSTOMER"
                      id="CUSTOMER"
                      value={formData.CUSTOMER}
                      onChange={onInputChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                        focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  {/* CALLED_BY and PHONE Inline */}
                  <div className="flex space-x-4">
                    {/* CALLED_BY */}
                    <div className="flex-1">
                      <label htmlFor="CALLED_BY" className="block text-sm font-medium text-gray-700">
                        Called By <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="CALLED_BY"
                        id="CALLED_BY"
                        value={formData.CALLED_BY}
                        onChange={onInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                          focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </div>

                    {/* PHONE */}
                    <div className="flex-1">
                      <label htmlFor="PHONE" className="block text-sm font-medium text-gray-700">
                        Phone <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="PHONE"
                        id="PHONE"
                        value={formData.PHONE}
                        onChange={onInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                          focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </div>
                  </div>

                  {/* Time / schedule */}
                  <div className="flex space-x-4">
                    {/* TIME */}
                    <div className='flex-1'>
                      <label htmlFor="TIME" className="block text-sm font-medium text-gray-700">
                        Time
                      </label>
                      <input
                        type="time"
                        name="TIME"
                        id="TIME"
                        value={formData.TIME}
                        onChange={onInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                          focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    {/* SCHEDULE_DATE */}
                    <div className='flex-1'>
                      <label htmlFor="SCHEDULE_DATE" className="block text-sm font-medium text-gray-700">
                        Schedule Date
                      </label>
                      <input
                        type="date"
                        name="SCHEDULE_DATE"
                        id="SCHEDULE_DATE"
                        value={formData.SCHEDULE_DATE}
                        onChange={onInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                          focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  {/* Tech */}
                  <div className="sm:col-span-2">
                    <label htmlFor="employeeSelect" className="block text-sm font-medium text-gray-900">
                      Select an Employee:
                    </label>
                    <select
                      id="employeeSelect"
                      name="TECH"
                      value={formData.TECH}
                      onChange={onInputChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm
                               focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option value="">---</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={`${employee.first_name} ${employee.last_name}`}>
                          {employee.first_name} {employee.last_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* NOTES */}
                  <div>
                    <label htmlFor="NOTES" className="block text-sm font-medium text-gray-700">
                      Notes
                    </label>
                    <textarea
                      name="NOTES"
                      id="NOTES"
                      value={formData.NOTES}
                      onChange={onInputChange}
                      rows={3}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                        focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    ></textarea>
                  </div>

                  {/* Form Buttons */}
                  <div className="flex justify-end space-x-2">
                    <button type="button" onClick={onClose}
                            className="inline-flex justify-center rounded-md border border-transparent
                                      bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 
                                      shadow-sm hover:bg-gray-400">
                      Cancel
                    </button>

                    <button type="submit"
                      className="inline-flex justify-center rounded-md border 
                                 border-transparent bg-blue-600 px-4 py-2 text-sm 
                                 font-medium text-white shadow-sm hover:bg-blue-700">
                      {isEditing ? 'Update' : 'Save'}
                    </button>

                  </div>

                </form>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default ServiceCallFormModal;
