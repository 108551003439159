import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import LoadingSpinner from './LoadingSpinner';
import HeadingCp from './HeadingCp';
import DateFormat from './DateFormat';
import { CheckIcon } from '@heroicons/react/24/outline';
import { UtilDateToFullDay } from '../utils/UtilDateToFullDay';
import ReimbursementListCp from './ReimbursementListCp';
import { useUserData } from '../context/UserData';

const OfficeTimesheetListCp = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [payperiod, setPayperiod] = useState(null);
  const [week, setWeek] = useState('active_week');
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const userData = useUserData();

  // State variable for tab selection
  const [activeTab, setActiveTab] = useState('timesheet');

  // State for reimbursements and search term
  const [reimbursements, setReimbursements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (activeTab === 'timesheet') {
      fetchEmployeeList(week);
    } else if (activeTab === 'reimbursement') {
      fetchReimbursements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, activeTab]);

  const formatTime12Hour = (time) => {
    let [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${suffix}`;
  };

  const fetchEmployeeList = async (week) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?office_timesheet_list=${week}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      });
      if (response.data.success) {
        const employee_list = Array.isArray(response.data.employees) ? response.data.employees : [];
        setEmployees(employee_list);
        setPayperiod(response.data.pay_period);
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const approved_ot = async (employee_id, overtimeHrs) => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'overtime_office_timesheet',
        data: { employee_id: employee_id, overtime_hrs: overtimeHrs, pay_period: payperiod },
      });
      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
        setEmployees((prevEmployees) =>
          prevEmployees.map((employee) =>
            employee.employee_id === employee_id ? { ...employee, approval: response.data[1] } : employee
          )
        );
      }

      if (response.data.error) {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setIsOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedEmployee(null);
  };

  // Fetch Reimbursements
  const fetchReimbursements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?reimbursements=true`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        setReimbursements(response.data.reimbursements);
      } else {
        toast.error(response.data.error || 'Failed to fetch reimbursements');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle update Reimbursement
  const handleEditReimbursement = async (editedReimbursement) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'adminUpdateReimbursement',
        data: editedReimbursement,
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) =>
          prevReimbursements.map((reimbursement) =>
            reimbursement.id === editedReimbursement.id ? editedReimbursement : reimbursement
          )
        );
        toast.success(response.data.success);
      } else {
        toast.error(response.data.error || 'Failed to update reimbursement');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  const handleAddReimbursement = async (newReimbursement, selectEmployee) => {
    if (!selectEmployee.id) {
      toast.info('Please select an employee.');
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'addReimbursementAdmin',
        data: newReimbursement,
        employee_id: selectEmployee.id,
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) => [...prevReimbursements, response.data.reimbursement]);
        toast.success('Reimbursement added successfully');
      } else {
        toast.error(response.data.error || 'Failed to add reimbursement');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  // Handle DELETE Reimbursement
  const handleDeleteReimbursement = async (id) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'deleteReimbursement',
        id: id,
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) =>
          prevReimbursements.filter((reimbursement) => reimbursement.id !== id)
        );
        toast.success('Reimbursement deleted successfully');
      } else {
        toast.error(response.data.error || 'Failed to delete reimbursement');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  // Handle file upload for Reimbursement
  const handleFileUpload = async (file, reimbursementId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'submit_reimbursement');
      formData.append('file', file);
      formData.append('id', reimbursementId);

      const response = await axios.post(`${apiUrl}/upload_reimbursement_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) =>
          prevReimbursements.map((reimbursement) =>
            reimbursement.id === reimbursementId
              ? { ...reimbursement, file: response.data.rem_file }
              : reimbursement
          )
        );
        toast.success('File uploaded successfully');
      } else {
        toast.error(response.data.error || 'Failed to upload file');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  const approvedRem = async (id) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'approvedReimbursement',
        data: { id },
      });
      if (response.data.success) {
        const updatedReimbursement = response.data.reimbursement; // Assuming this contains the updated reimbursement object
        setReimbursements((prevReimbursements) =>
          prevReimbursements.map((reimbursement) =>
            reimbursement.id === updatedReimbursement.id ? updatedReimbursement : reimbursement
          )
        );
        toast.info(response.data.success, { position: 'top-center' });
      } else {
        toast.info(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', {
          position: 'top-center',
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <HeadingCp label={'Office Timesheet List (Admin)'} />

        {/* Tab Navigation */}
        <div className="border-b border-gray-200 mb-4">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <button
              onClick={() => setActiveTab('timesheet')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'timesheet'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Timesheet List
            </button>

            <button
              onClick={() => setActiveTab('reimbursement')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'reimbursement'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Reimbursement List
            </button>
          </nav>
        </div>

        {/* Conditionally render content based on active tab */}
        {activeTab === 'timesheet' && (
          <>
            <h6 className="py-8">
              Payroll Date:&nbsp;
              {payperiod ? <DateFormat date={payperiod.nextPayPeriod} /> : '(No timesheets)'}
            </h6>
            {loading ? (
              <LoadingSpinner /> // Display a loading spinner if loading
            ) : (
              <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
                <thead>
                  <tr>
                    <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Approval</th>
                    <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Employee Name</th>
                    <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Department</th>
                    <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Overtime</th>
                    <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Timesheet Entries</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {employees.map((employee, index) => (
                    <tr
                      key={employee.employee_id}
                      className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                    >
                      <td className="px-3 py-4 text-sm text-blue-500">
                        {employee.approval?.id ? (
                          <CheckIcon className="h-6 text-green-500 px-5" />
                        ) : (
                          <button
                            onClick={() => approved_ot(employee.employee_id, employee.overtime_hrs)}
                            className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium 
                                      rounded-md shadow-sm text-white bg-green-500 hover:bg-blue-600 focus:outline-none 
                                      focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Approve
                          </button>
                        )}
                      </td>

                      <td className="px-3 py-4 text-sm text-blue-500">
                        <span className="cursor-pointer" onClick={() => handleEmployeeClick(employee)}>
                          {employee.employee_name}
                        </span>
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-900">{employee.department}</td>

                      <td className="px-3 py-4 text-sm text-gray-900">{employee.overtime_hrs} Hrs</td>

                      <td className="px-3 py-4 text-sm text-gray-900">
                        {employee.timesheets.length} entries
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}

        {activeTab === 'reimbursement' && (
          <ReimbursementListCp
            reimbursements={reimbursements}
            searchTerm={searchTerm}
            onEditReimbursement={handleEditReimbursement}
            onAddReimbursement={handleAddReimbursement}
            access={userData?.users?.department || 'User'} // Adjust as needed
            onDeleteReimbursement={handleDeleteReimbursement}
            onFileUpload={handleFileUpload}
            onApprovedReimbursement={approvedRem}
          />
        )}
      </div>

      {/* Modal for Timesheet Entries */}
      {selectedEmployee && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {selectedEmployee.employee_name} - Timesheet Entries
                    </Dialog.Title>
                    <div className="mt-4">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Event
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Time In
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Time Out
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Overtime
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {selectedEmployee.timesheets.map((timesheet, index) => (
                            <tr
                              key={timesheet.id}
                              className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                            >
                              <td className="px-3 py-4 text-sm text-gray-900">{timesheet.event}</td>
                              <td className="px-3 py-4 text-sm text-gray-900">
                                {formatTime12Hour(timesheet.time_in)}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-900">
                                {formatTime12Hour(timesheet.time_out)}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-900">{timesheet.overtime}</td>
                              <td className="px-3 py-4 text-sm text-gray-900">
                                {UtilDateToFullDay(timesheet.date)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-6 flex justify-end">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default OfficeTimesheetListCp;
