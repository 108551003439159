import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto"> 
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full pb-6">
                    <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {/* <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-1 py-1 bg-white-600 text-base font-medium text-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            > */}
              <XMarkIcon onClick={onClose} className='h-7 hover:cursor-pointer'/>
            {/* </button> */}
          </div>
          <div className="px-4 py-7 sm:p-6">
            {children}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Modal;
